@font-face {
  font-family: "Aaux ProBold";
  src: url("./Aaux ProBold.ttf") format('truetype');
}

@font-face {
  font-family: "Aaux ProBlack";
  src: url("./Aaux ProBlack.ttf") format('truetype');
}

@font-face {
  font-family: "Aaux ProLight OSF";
  src: url("./Aaux ProLight OSF.ttf") format('truetype');
}

@font-face {
  font-family: "Aaux ProMedium";
  src: url("./Aaux ProMedium.ttf") format('truetype');
}

@font-face {
  font-family: "Aaux ProRegular";
  src: url("./Aaux ProRegular.ttf") format('truetype');
}

@font-face {
  font-family: "Aaux ProRegular Italic";
  src: url("./Aaux ProRegular Italic.ttf") format('truetype');
}
